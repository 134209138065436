/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/photos/ledger-accounting-business-money-1428230/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theregister.com/2023/12/08/hashicorp_openbao_fork"
  }, "Open source forkers stick an OpenBao in the oven")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.linuxfoundation.org/blog/opentofu-release-candidate-is-out?utm_content=276063112&utm_medium=social&utm_source=twitter&hss_channel=tw-14706299"
  }, "OpenTofu Release Candidate Is Out, GA Set for Jan 10th")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/leg100/otf"
  }, "otf")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/mitchell-reflects-as-he-departs-hashicorp?fbclid=IwAR0Mfoi1s6rUTpQsow7TQqMN5gBABbRg9ZfPClhWkR3kWa1pXP62SzhDiRY"
  }, "Mitchell reflects as he departs HashiCorp")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.vagrantup.com/"
  }, "HashiCorp Vagrant")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://newrelic.com/"
  }, "New Relic")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://newrelic.com/press-release/20230731"
  }, "New Relic to be Acquired by Francisco Partners and TPG for $6.5 Billion")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://theviewers.co.kr/View.aspx?No=3057886"
  }, "넥슨 지주사 NXC 지분 결국 '유찰'…4.7조 부담됐나")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2023/12/18/24005996/adobe-figma-acquisition-abandoned-termination-fee"
  }, "Adobe abandons $20 billion acquisition of Figma")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://news.adobe.com/news/news-details/2023/Adobe-and-Figma-Mutually-Agree-to-Terminate-Merger-Agreement/default.aspx"
  }, "Adobe and Figma Mutually Agree to Terminate Merger Agreement")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
